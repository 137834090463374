import { courseResourceList } from '../../assets/js/data';
export default {
  name: 'search',
  props: ['propData'],
  data: function data() {
    return {
      //表单数据
      formInline: {
        user: '',
        region: ''
      },
      courseResourceList: courseResourceList,
      //默认搜索
      //表单
      searchData: null
    };
  },
  created: function created() {
    this.searchData = this.propData || this.courseResourceList;
  }
};